import React, { Component } from 'react'
import { connect } from 'react-redux'
import { handleStartFaceGlass } from '../redux/actions/player'
import Progress from './Progress'
import Page from './Page'

const status = [
    { status: 'Carregando perfil' , time: Math.floor(500/3),  maxPercent: 10 },
    { status: 'Entendendo perfil' , time: Math.floor(500/3),  maxPercent: 20},
    { status: 'Mapeando rosto'    , time: Math.floor((Math.random() * 5 + 3) * 1000/3),  maxPercent: 50},
    { status: 'Encontrando modelos' , time: Math.floor(500/3),  maxPercent: 20},
]

class FaceCalc extends Component {

    state = {
        percent: 0,
        current: 0
    }
    componentDidMount(){
       

        const time = Math.floor(Math.random() * 5 + 3 )
        let currentTime = 0;
        let realPercent = 0;

        this.interval = setInterval(() => {
            currentTime += 100;
            const currentStatus = status[this.state.current];

            const percertOfPart = Math.min( Math.floor( currentTime / currentStatus.time * 100), 100);
            this.setState({ percent : Math.min(realPercent + percertOfPart * (currentStatus.maxPercent / 100), 100)  })

            
            if(percertOfPart == 100){
                
                this.setState({ current : Math.min(this.state.current + 1 , status.length - 1  ) })
                realPercent += currentStatus.maxPercent
                currentTime = 0;
            }

            if(this.state.percent == 100)
                this.props.dispatch(handleStartFaceGlass())
        }, 100)
    }

    componentWillUnmount(){
        clearInterval(this.interval)
    }

    render() {

        const currentStatus = status[this.state.current];

        return <Page subtitle={currentStatus.status} >
                <Progress borderRadius percent={this.state.percent} />
        </Page>
    }
}

export default connect()(FaceCalc)