import React, { Component } from 'react'
import { connect } from 'react-redux'
import { handleSetAge, handleSetGender, setStatus, handleShareImg } from '../redux/actions/player'
import { status } from '../utils/const';
import Instructions from './Instructions';
import { Redirect } from 'react-router-dom'
class Start extends Component{

    state = {
        redirect: false
    }

    componentDidMount(){
        this.props.dispatch(handleShareImg(this.props.match.params.id))
    }
    //
    //
    ///
    //
    render(){

        const props = !this.props.shareImg ? {
            title: 'Quer descobrir os melhores modelos de óculos de sol para você?',
                subtitle: 'Faremos rápidas perguntas e uma análise do seu rosto.',
                img:'/assets/img/start.svg',
                btnClick: () => this.props.dispatch(setStatus(status.PLAYER_INFO_GENDER)),
                btn: 'Sim, quero!'
        } : {
            title: 'Quer descobrir os melhores modelos de óculos de sol para você?',
                subtitle: 'Olha como ficou bem esse óculos em mim:',
                img:this.props.shareImg ,
                btnClick: () => {
                    this.setState({ redirect: true})
                    this.props.dispatch(setStatus(status.PLAYER_INFO_GENDER))
                },
                btn: 'Experimentar!'
        }

        if(this.state.redirect)
            return <Redirect path="/" />
        return <Instructions 
                {...props}
        />
    }
}

function mapStateToProps({ player }){
    return {
        shareImg: player.shareImg
    }
}


export default connect(mapStateToProps)(Start)