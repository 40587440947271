import React, { useEffect, Component } from 'react';
import logo from './logo.svg';
import './App.css';
import FaceReader from './components/FaceReader';
import FaceGlass from './components/FaceGlass';
import User from './components/User';
import { connect } from 'react-redux'
import { status } from './utils/const';
import UserStyle from './components/UserStyle';
import FaceCalc from './components/FaceCalc';
import Start from './components/StartInstructions';
import FaceReaderInstructions from './components/FaceReaderInstructions';
import { handlePreLoadModules } from './redux/actions/player';
import { Switch, Route } from 'react-router-dom';
import Share from './components/Share';
import * as Sentry from '@sentry/browser';


Sentry.init({dsn: "https://bd9043ed413542c3a1975b1c20331017@sentry.io/5186794"});

class App extends Component {

  componentDidMount(){
    this.props.dispatch(handlePreLoadModules())

    // window.addEventListener('resize', () => this.setState({ a: Math.random() }))
  }

  render(){
    
    var ua = navigator.userAgent
    const isMobile = [
        /iPad/.test(ua),
        /iPhone/.test(ua),
        /Android 4/.test(ua)
    ].reduce((a, b) => a || b, false)

  const { currentStatus } = this.props
  return (<div className="d-flex flex-fill" style={{ height: isMobile ? window.innerHeight + 'px' :  '100vh', padding: '16px', background: '#f9f9f9'}}>
      <div className="d-flex flex-fill h-100 w-100 app-box">
      <Switch>
        <Route exact path="/" component={() => (
          <>
            { (currentStatus == '') && <Start />}
            { (currentStatus == status.PLAYER_INFO_AGE || currentStatus == status.PLAYER_INFO_GENDER) && <User />}
            { currentStatus == status.PLAYER_STYLES && <UserStyle />}
            { currentStatus == status.INSTRUCTION_FACE_READER && <FaceReaderInstructions />}
            { currentStatus == status.FACE_READER && <FaceReader />}
            { currentStatus == status.FACE_CALC && <FaceCalc />}
            { currentStatus == status.FACE_GLASS && <FaceGlass />} 
          </>
        )} />
        
        <Route path="/share/:id" exact component={Share} />
      </Switch>
      
      {/* <FaceReader />*/}
      </div>
    </div>
    );
  }
}

function mapStateToProps({ player }){
  return {
    currentStatus: player.status,
    player
  }
}

export default connect(mapStateToProps)(App);
