import React, { Component } from 'react'
import { connect } from 'react-redux'
import { handleSetAge, handleSetGender, setStatus } from '../redux/actions/player'
import { status } from '../utils/const';
import Step from './Step';

export default function Page(props) {
    return (<div className="d-flex flex-fill flex-column align-items-center" style={{ padding: '20px', paddingTop: '24px', paddingBottom: '24px' }}>
        <div className="d-flex text-center" style={{ fontSize: '24px', marginTop: '24px', fontWeight: 'bold', lineHeight: 1.33 }}>{props.title}</div>
        <div className="d-flex text-center" style={{ fontSize: '18px', marginTop: '32px', lineHeight: 1.5 }}>{props.subtitle}</div>
        {props.step !== undefined &&  <div className="d-flex " style={{ width: '40%' }}>
            <Step step={props.step} />
        </div>}
        <div className="d-flex  w-100 align-items-start justify-content-start" style={{ flex: 1,fontSize: '32px', marginTop: '32px', borderBottom: props.back !== undefined  ? '1px solid #3636363d' : '' }} >
            {props.children}
        </div>
        {
            props.back !== undefined && <div className="d-flex text-center align-items-center" onClick={() => props.back()} style={{ marginTop: '24px', borderRadius: '8px', padding: '12px', fontSize: '16px', color: '#3a3838', border: '1px solid #b2b2b2',  cursor: "pointer", }}><img className="d-flex" style={{ height: '16px', marginRight: '8px' }} src={'/assets/img/back.svg'} /> <div className="d-flex">Voltar</div></div>
        }
        
    </div>)
}