import React, { Component } from 'react'
import { connect } from 'react-redux'
import { handleSetAge, handleSetGender, setStatus } from '../redux/actions/player'
import { status } from '../utils/const';
import Step from './Step';
import Page from './Page';

class User extends Component {


    handleSetGender() {
        // const { age, gender } = this.state

        // if(gender == '' || age == '') return;

        // this.props.dispatch(setPlayerInfo(gender, age))
    }

    render() {

        const { currentStatus, gender, age } = this.props

        const genderOptions = [
            ['homem',
            'mulher'],
            ['ambos',
            'nenhum']
        ]
        if (currentStatus == status.PLAYER_INFO_GENDER)
            return <Page title={"Vamos começar!"} subtitle={"Qual seu gênero?"} step={1} back={() => this.props.dispatch(setStatus(''))}>
                <div className="d-flex flex-column w-100">
                {
                    genderOptions.map((op, i) => (
                        <div  key={i}  className="d-flex flex-row w-100" style={{ marginTop: i == 0 ? '0px' :  '24px'}}>
                            { op.map((o, index) => <div key={index} onClick={() => this.props.dispatch(handleSetGender(o))} className="d-flex  gender-box justify-content-center align-items-center" style={{ padding: '20px', flex: 1, height: '96px', marginRight: index == 0 ? '24px' : '0px', background: gender == o ? '#ffc000' : '#ffffff', fontSize: '20px' }}>{o}</div> )}
                        </div>
                    ))
                }
                </div>
                
            </Page>

        const ages1 = [
            '15-25',
            '26-40']
        const ages2 = [
            '41-60',
            '61+',
        ]

        
        const ageOptions = [
            ['15-25',
            '26-40'],
            ['41-60',
            '61+']
        ]

        return <Page title={"Idade"} subtitle={"Qual sua faixa etária?"} step={2} back={() => this.props.dispatch(setStatus(status.PLAYER_INFO_GENDER))}>
           
           <div className="d-flex flex-column w-100">
                {
                    ageOptions.map((op, i) => (
                        <div key={i} className="d-flex flex-row w-100" style={{ marginTop: i == 0 ? '0px' :  '24px'}}>
                            { op.map((o, index) => <div key={index} onClick={() => this.props.dispatch(handleSetAge(o))} className="d-flex  gender-box justify-content-center align-items-center" style={{ padding: '20px', flex: 1, height: '96px', marginRight: index == 0 ? '24px' : '0px', background: age == o ? '#ffc000' : '#ffffff', fontSize: '20px' }}>{o}</div> )}
                        </div>
                    ))
                }
                </div>
        </Page>

        // return <div className="d-flex flex-fill flex-column w-100">

        //     {ages.map(a => <button key={a} onClick={() => this.props.dispatch(handleSetAge(a))} style={{ fontWeight: a == age ? 'bold' : 'normal' }}>{a}</button>)}
        //     <br />
        //     <button onClick={() => this.props.dispatch(setStatus(status.PLAYER_INFO_GENDER))}>Voltar</button>
        // </div>
    }
}

function mapStateToProps({ player }) {
    return {
        currentStatus: player.status,
        age: player.age,
        gender: player.gender,
    }
}

export default connect(mapStateToProps)(User)