import { SET_PLAYER_INFO, SET_PLAYER_MODEL, SET_SHARE_IMG, SET_PLAYER_QUESTION, SET_RECOMENDATIONS, SET_CURRENT_QUESTION, SET_STATUS, SET_PLAYER_INFO_AGE, SET_PLAYER_INFO_GENDER, SET_CURRENT_QUESTION_ANSWER, SET_PLAY_ID } from "../actions/player";
import { status } from "../../utils/const";


const stateDefault = {
    
    status: '',

    gender: '',
    age: '',

    //
    currentQuestion: 0,
    questions: {
      
    },

    faceReader: {

    }
}

export default function player(state = stateDefault, action){
    console.log(action)
    switch (action.type) {
        case SET_PLAY_ID:
            return {
                ...state,
                playId: action.playId,
                userId: action.userId,
            }
        case SET_PLAYER_INFO:
            return {
                ...state,
                ...action.info,
                status: action.status,
            }
        case SET_PLAYER_MODEL:
            return {
                ...state,
                model: action.model,
            }
        case SET_PLAYER_INFO_AGE:
            return {
                ...state,
                age: action.age,
            }
        case SET_PLAYER_INFO_GENDER:
            return {
                ...state,
                gender: action.gender
            }
       
        case SET_SHARE_IMG:
            return {
                ...state,
                shareImg: action.shareImg
            }
        case SET_PLAYER_QUESTION:
            return {
                ...state,
                questions: action.questions
            }
        case SET_STATUS:
            return {
                ...state,
                status: action.status
            }
        case SET_CURRENT_QUESTION:
            return {
                ...state,
                currentQuestion: action.currentQuestion
            }
        case SET_RECOMENDATIONS:
            return {
                ...state,
                recomendations: action.recomendations
            }
            
        case SET_CURRENT_QUESTION_ANSWER:
            return {
                ...state,
                questions: {
                    ...state.questions,
                    [state.currentQuestion] : {
                        ...state.questions[state.currentQuestion],
                        answer: action.answer
                    }
                    // action.currentQuestion
                }
            }
        default:
            return state
    }
}

