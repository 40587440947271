import React, { Component } from 'react'
import { connect, useDispatch } from 'react-redux'
import LazyLoad from 'react-lazy-load';
import { handleClearFilter, handleToggleFilter, handleSearchByName } from '../redux/actions/filter';


const FilterChips = ({ type, render, title, width, tags, selected, padding = 22, height = 48, margin = 16, borderRadius = 24 }) => {
    const dispatch = useDispatch();

    return (
        <div className="d-flex flex-column" style={{ marginTop: '24px', color: '#6a6b6c', fontWeight: 'bold', fontSize: '16px' }}>
            <div>{title}</div>
            <div className="d-flex" style={{ marginTop: '16px', flexWrap: 'wrap' }}>

                {
                    tags.map(t => (
                        <div
                            key={t.value}
                            className="d-flex align-items-center justify-content-center"
                            style={{
                                height: `${height}px`,
                                width: width,
                                borderRadius: `${borderRadius}px`,
                                fontSize: '14px',
                                border: selected.includes(t.value) ? '1.5px solid #ffc000' : '1.5px solid #6a6b6c',
                                background: selected.includes(t.value) ? '#ffc000' : '',
                                padding: `0 ${padding}px`,
                                fontWeight: 'normal',
                                color: '#363636',
                                marginRight: `${margin}px`,
                                marginBottom: `8px`,
                                cursor: 'pointer'
                            }}
                            onClick={() => dispatch(handleToggleFilter(type, t.value))}>
                            <div>{render ? render(t) : t.name}</div>
                        </div>
                    ))
                }


            </div>
        </div>
    )
}

class Filter extends Component {

    //
    //
    ///
    //
    state = {
        filter: false,
        text: ''
    }

    // 5953bea50ec85313791c9aa7 Ray-Ban
    // 5953bea50ec85313791c9aa7 Carrera
    componentDidMount() {
        this.textChange('')
    }

    textChange(v) {
        this.setState({ text: v })
        this.props.dispatch(handleSearchByName(v))
    }

    renderResult() {

        const { filter } = this.props
        const results = this.props.results || []
        const brands = {
            "5953bea50ec85313791c9aa7": "Ray-Ban",
            "5975fa7213beab44d3b6c6cc": "Carrera",
            "5979e77f3d20d717d895fefd": "Oakley",
            "598c5cc3fc64dd436fac149b": "AliExpress",
            "59a69562645e5a6d9aa94727": "Persol",
            "59b2b45a553d246a2c652638": "Polaroid",
            "5991c693e40a5f493aa48c4a": "-",
        }

        const sort = [
            'gender',
            'brand',
            'shape',
            'material'
        ]

        return <div className="d-flex flex-column flex-fill w-100 h-100 position-absolute" style={{ zIndex: 900, background: '#f9f9f9', top: 0, left: 0, padding: '24px 16px' }}>
            <div className="d-flex w-100 align-items-center" style={{ fontSize: '16px' }} onClick={() => this.props.onSelect(undefined)}>
                <img className="d-flex" style={{ height: '16px', marginRight: '8px' }} src={'/assets/img/back.svg'} />  Voltar
            </div>
            <div className="d-flex w-100 align-items-center" style={{ height: '48px', minHeight: '48px', marginTop: '24px', padding: '8px 12px', borderRadius: '8px', background: 'white', boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.16)' }}>
                <img className="d-flex" style={{ height: '12px', marginTop: '2px', marginRight: '4px' }} src={'/assets/img/search.svg'} />
                <input onChange={(e) => this.textChange(e.target.value)} value={this.state.text} className="d-flex flex-fill " placeholder="Buscar" style={{ fontSize: '14px', padding: 0, outline: 'none', border: 'none' }} />
                <div className="d-flex h-100" style={{ width: '.5px', background: '#b2b2b2' }}></div>
                <div className="d-flex h-100 align-items-center justify-content-center" onClick={() => this.setState({ filter: true, text: "" })} style={{ width: '64px', fontSize: '16px', color: '#363636' }}>
                    <div className="d-flex">Filtros</div>
                </div>
            </div>
            <div className="d-flex w-100" style={{ overflow: 'auto', marginTop: '8px', minHeight: Object.keys(filter).map(i => filter[i].selected).flat().length == 0 ? '0px': '36px', fontSize: '14px'}}>
                {
                    Object.keys(filter).sort((a, b) => sort.indexOf(b) - sort.indexOf(a)).map(i => filter[i].selected.map(s => (
                        <div
                            className="d-flex"
                            key={s}
                            style={{
                                background: '#fcdc7c',
                                borderRadius: '16px',
                                padding: '8px 12px',
                                marginRight: '8px'
                            }}
                        >{filter[i].options.find(e => e.value == s).name}
                            <div onClick={() => this.props.dispatch(handleToggleFilter(i, s))} className="d-flex aling-items-center justify-content-center" style={{ width: '16px', marginLeft: '8px'}}>
                                <img src="/assets/img/close-round.svg" width="100%" height="100%" />
                            </div>
                        </div>
                    )) )
                }
            </div>

            <div className="d-flex w-100" style={{ minHeight: '27px', marginTop: '24px', color: '#363636', fontSize: '18px', fontWeight: 'bold' }}>
                Todos os modelos {results.length !== 0 && ` (${results.length})`}
            </div>



            <div className="d-flex w-100 flex-fill" style={{ flexWrap: 'wrap', overflow: 'auto' }}>
                {results.length == 0 && <p>Sem resultados</p>}
                {results.map((i, index) => (

                    <div key={index} className="d-flex flex-column align-items-stretch" style={{ marginLeft: '8px', marginRight: '8px', marginTop: '16px', minheight: '225px', maxHeight: '230px', width: 'calc(50% - 16px)', maxWidth: '164px', boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.16)', borderRadius: '8px' }}>
                        <div className="d-flex flex-fill w-100 position-relative">
                            <LazyLoad>
                                <img className="w-100" style={{ objectFit: 'contain' }} src={i.meta.productImageURL} />
                            </LazyLoad>
                        </div>
                        <div style={{ padding: '8px', paddingTop: '0px', bottom: 0 }}>
                            <b>{brands[i.meta.brand]}</b>
                            <div style={{ maxHeight: '44px', overflow: 'hidden', }}>{i.name}</div>
                        </div>
                        <div className="d-flex w-100 align-items-center justify-content-center" style={{
                            background: '#ffc000',
                            color: '#363636',
                            borderRadius: '0px 0 8px  8px',
                            minHeight: '36px',
                            height: '40px',
                        }} onClick={() => this.props.onSelect(i.sku)}><span className="d-flex">Experimentar</span></div>
                    </div>
                ))}
            </div>

        </div>
    }

    renderFilter() {

        const { filter } = this.props

        const getStyleConfig = (type) => {
            if (type == 'options-big') return {}
            if (type == 'options') return {
                margin: 8,
                padding: 12,
                height: 32
            }

            return {
                margin: 8,
                padding: 12,
                height: 56,
                borderRadius: 8,
                width: 'calc(33% - 8px)'
            }
        }

        return <div className="w-100 h-100" style={{ background: '#f9f9f9' }}>
            <div className="d-flex flex-column  position-relative" style={{
                display: 'flex',
                flex: 1,
                boxShadow: `0 1px 3px 0 rgba(0, 0, 0, 0.16)`,
                padding: '16px',
                margin: '16px',
                borderRadius: '8px',
                background: 'white'
            }}>
                <div className="d-flex flex-row align-items-center w-100" style={{ boxSizing: 'unset', height: '22px', position: 'relative', paddingBottom: '8px', borderBottom: '1px solid #eaeaea' }}>
                    <div onClick={() => this.setState({ filter: false })} style={{
                        position: 'absolute',
                        left: '0px'
                    }}> <img src="/assets/img/close.svg" /> </div>
                    <div className="d-flex flex-fill align-items-center justify-content-center" style={{
                        color: '#363636',
                        fontWeight: 'bold',
                        fontSize: '16px'
                    }}><div className="d-flex">Filtros</div></div>
                    <div
                        onClick={() => this.props.dispatch(handleClearFilter())}
                        style={{
                            color: '#6a6b6c',
                            fontSize: '14px',
                            textDecoration: 'underline',
                            position: 'absolute',
                            right: '0px'
                        }}>Limpar filtros</div>
                </div>
                <div className="d-flex flex-column flex-fill position-relative" style={{ paddingBottom: '56px', overflow: 'auto' }}>

                    {
                        Object.keys(filter).map(type => (
                            <FilterChips key={type} type={type} title={filter[type].title} tags={filter[type].options} selected={filter[type].selected} render={filter[type].render} {...getStyleConfig(filter[type].type)} />
                        ))
                    }

                    <div onClick={() => this.setState({ filter: false })} className="d-flex position-absolute align-items-center justify-content-center w-100" style={{ bottom: '0', cursor: "pointer", borderRadius: '8px', minHeight: '56px', fontSize: '18px', color: '#3a3838', background: '#ffc000', }}>
                        <div className="d-flex">Filtrar</div>
                    </div>
                </div>

               

            </div>
        </div>
    }

    render() {
        return this.state.filter ? this.renderFilter() : this.renderResult()
    }
}

function mapStateToProps({ filter }) {
    return {
        filter: filter.filters,
        results: filter.results
    }
}

export default connect(mapStateToProps)(Filter)