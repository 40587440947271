import React, { Component } from 'react'
import { connect } from 'react-redux'
import { handleSetAge, handleSetGender, setStatus } from '../redux/actions/player'
import { status } from '../utils/const';
import Instructions from './Instructions';

class Start extends Component{

    //
    //
    ///
    //
    render(){
        return <Instructions 
                title={'Para analisarmos seu rosto, permita a seguir o uso da sua câmera.'}
                subtitle={<span>Fique tranquilo! Vamos apenas analisar suas <b>características faciais</b>.</span>}
                img={'assets/img/scan.svg'}
                btnClick={() => this.props.dispatch(setStatus(status.FACE_READER))}
                btn={'Ok, continuar'}
        />
    }
}


export default connect()(Start)