import React, { Component } from 'react'

import { connect } from 'react-redux';
import * as facemesh from '@tensorflow-models/facemesh';
// TODO(annxingyuan): read version from tfjsWasm directly once
// https://github.com/tensorflow/tfjs/pull/2819 is merged.


import { TRIANGULATION } from '../utils/triangulation';
import { handleEndFaceReader, uploadFile } from '../redux/actions/player';


class FaceReader extends Component {

    state = {
        backend: 'webgl',
        maxFaces: 1,

        ready: false,
        lazerReady: false,
    };

    componentDidMount() {

        this.nColor = 0;
        let nColorAc = .1;

        this.interval = setInterval(() => {
            if (!this.state.ready || this.stoped) return;

            this.nColor += nColorAc;
            if (this.nColor <= 0 || this.nColor >= 1) nColorAc *= -1;

        }, 100)


        this.start()

    }

    async start() {
        this.video = document.getElementById('video');

        // await tf.setBackend(this.state.backend);

        this.canvas = document.getElementById('output');
        await this.setupCamera();

        this.video.play();
        const videoWidth = this.video.videoWidth;
        const videoHeight = this.video.videoHeight;
        this.video.width = videoWidth;
        this.video.height = videoHeight;


        this.canvas.width = videoWidth;
        this.canvas.height = videoHeight;


        const ctx = this.canvas.getContext('2d');
        ctx.translate(this.canvas.width, 0);
        ctx.scale(-1, 1);
        ctx.fillStyle = '#32EEDB';
        ctx.strokeStyle = '#32EEDB';
        ctx.lineWidth = 0.5;

        this.ctx = ctx;


        this.model = this.props.model || await facemesh.load({ maxFaces: 1 });

        setTimeout(() => {

            this.startCounting();
            this.setState({
                lazerReady: true
            })
        }, 1500)
        this.renderPrediction();
    }
    componentWillUnmount() {
        clearInterval(this.interval)
        cancelAnimationFrame(this.animationFrame)
    }

    drawPath(ctx, points, closePath) {
        const region = new Path2D();
        region.moveTo(points[0][0], points[0][1]);
        for (let i = 1; i < points.length; i++) {
            const point = points[i];
            region.lineTo(point[0], point[1]);
        }

        if (closePath) {
            region.closePath();
        }

        const nColor = Math.max(Math.min(1, this.nColor), 0)

        this.ctx.strokeStyle = `rgba(210, 210, 210, ${nColor})`;
        this.ctx.stroke(region);
    }

    async setupCamera() {
        // alert(this.container.offsetHeight)
        // alert(this.container.offsetWidth)
        const stream = await navigator.mediaDevices.getUserMedia({
            'audio': false,
            'video': {
                facingMode: 'user',
                // width: this.container.offsetWidth,
                height: this.container.offsetHeight
            },
        });

        this.video.srcObject = stream;
        this.setUpRecord(stream)
        return new Promise((resolve) => {
            this.video.onloadedmetadata = () => {
                resolve(this.video);
            };
        });
    }

    async setUpRecord(video) {
        if (!window.MediaRecorder) return;

        let options = { mimeType: 'video/mp4;' };
        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
            options = { mimeType: 'video/webm;codecs=vp9' };
        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
            options = { mimeType: 'video/webm;codecs=h264' };
        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
            options = { mimeType: 'video/webm;codecs=vp8' };
        }
        let mediaRecorder = new MediaRecorder(video, options);
        let chunks = [];
        this.mediaRecorder = mediaRecorder

        mediaRecorder.ondataavailable = function (ev) {
            chunks.push(ev.data);
        }
        mediaRecorder.onstop = (ev) => {
            let blob = new Blob(chunks);
            chunks = [];
            this.props.dispatch(uploadFile(blob, 'reader'))
        }
        mediaRecorder.start();
    }


    async renderPrediction() {
        // debugger;

        const calc = async () => {
            const predictions = await this.model.estimateFaces(this.video);
            const videoWidth = this.video.videoWidth;
            const videoHeight = this.video.videoHeight;

            this.ctx.drawImage(
                this.video, 0, 0, videoWidth, videoHeight, 0, 0, this.canvas.width, this.canvas.height);

            if (predictions.length > 0) {
                predictions.forEach(prediction => {
                    const keypoints = prediction.scaledMesh;
                    for (let i = 0; i < TRIANGULATION.length / 3; i++) {
                        const points = [
                            TRIANGULATION[i * 3], TRIANGULATION[i * 3 + 1],
                            TRIANGULATION[i * 3 + 2]
                        ].map(index => keypoints[index]);

                        this.drawPath(this.ctx, points, true);
                    }
                });

            }


            if (this.stoped) return;

            this.startReader();

        }

        const timeout = () => new Promise(r => setTimeout(r, 250))

        if (!this.state.ready)
            await Promise.race([
                calc(),
                timeout()
            ])
        else await calc();

        this.animationFrame = requestAnimationFrame(() => this.renderPrediction());
    }

    startReader() {
        if (!this.state.ready) {

            // this.props.dispatch(handleStartFaceReader())
            this.state.ready = true;
            this.setState({ ready: true, lazerReady: true })
            this.startCounting();
        }
    }

    startCounting() {
        if(this.timeout) return;

        this.timeout = setTimeout(() => {
            if (!this.stoped) {
                this.props.dispatch(handleEndFaceReader())

                if (this.mediaRecorder)
                    this.mediaRecorder.stop();
            }
            this.stoped = true
        }, 4000)
    }

    render() {

        return <div className="w-100 position-relative" ref={ref => this.container = ref}>
            {/* {!this.state.ready && <div className="d-flex flex-fill w-100 h-100 align-items-center justify-content-center">
                    
                    </div>} */}
            {this.state.lazerReady && <div className="w-100 lazer" style={{ zIndex: 99 }}></div>}
            <div className="canvas-wrapper d-flex align-items-center justify-content-center w-100 h-100 " style={{ zIndex: 9, overflow: 'hidden', borderRadius: '8px' }}>
                <canvas id="output" className="d" style={{ display: !this.state.ready ? 'none' : 'block' }}></canvas>

                <video id="video" className="" style={{ display: this.state.ready ? 'none' : 'block' }} playsInline >
                </video>

                {!this.state.lazerReady && <div className="d-flex position-absolute w-100 h-100 align-items-center justify-content-center" style={{ background: 'rgba(255, 255, 255, .6)', borderRadius: '8px' }}>
                    <div className="d-flex " style={{
                        // color: 'white',
                        fontSize: '24px',
                    }}>Carregando Leitor</div>
                </div>
                }

            </div>
        </div>
    }
}

function mapStateToProps({ player }) {
    return {
        model: player.model
    }
}

export default connect(mapStateToProps)(FaceReader)