import { status } from "../../utils/const"
import * as facemesh from '@tensorflow-models/facemesh';
import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import { version } from '@tensorflow/tfjs-backend-wasm/dist/version';
import * as API from '../../utils/api';
import { search, share } from '../../utils/api'

export const SET_PLAYER_INFO = 'SET_PLAYER_INFO'
export const SET_PLAYER_MODEL = 'SET_PLAYER_MODEL'
export const SET_PLAYER_INFO_GENDER = 'SET_PLAYER_INFO_GENDER'
export const SET_PLAYER_INFO_AGE = 'SET_PLAYER_INFO_AGE'
export const SET_PLAYER_QUESTION = 'SET_PLAYER_QUESTION'
export const SET_STATUS = 'SET_STATUS'
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION'
export const SET_CURRENT_QUESTION_ANSWER = 'SET_CURRENT_QUESTION_ANSWER'
export const SET_PLAY_ID = 'SET_PLAY_ID'
export const SET_RECOMENDATIONS = 'SET_RECOMENDATIONS'
export const SET_SHARE_IMG = 'SET_SHARE_IMG'




const questions = {
    '0': {
        id: 1,
        step: 0,
        type: 'mult-text',
        title: 'Personalidade',
        subtitle: 'Com qual das opções você mais se identifica??',
        options: [
            'Extroversão, energia, otimismo',
            'Sutileza, calmaria, sensibilidade',
            'Determinação, rapidez, intensidade',
            'Reflexão, constância, pragmatismo'
        ]
    },

    '1': {
        id: 3,
        step: 1,
        type: 'imgs',
        title: 'Estilo de roupa',
        subtitle: 'Qual das opções tem o estilo mais próximo do que você se identifica?',
        options: ['/assets/img/q1-man-a.jpg', '/assets/img/q1-man-b.jpg'],
        values: ['Casual', 'Social']
    },

    '2': {
        id: 3,
        step: 2,
        type: 'imgs',
        title: 'Estilo de vida',
        subtitle: 'Com qual das opções você mais se identifica?',
        options: ['/assets/img/q2-a.jpg', '/assets/img/q2-b.jpg'],
        values: ['Cidade', 'Campo']
    }
}



function setPlayerInfo(gender, age) {
    return {
        type: SET_PLAYER_INFO,
        info: {
            gender,
            age
        },
        status: status.PLAYER_STYLES
    }
}

function setRecomendations(recomendations){
    return {
        type: SET_RECOMENDATIONS,
        recomendations
    }
}

function setModel(model) {
    return {
        type: SET_PLAYER_MODEL,
        model
    }
}

function setQuestion(questions) {
    return {
        type: SET_PLAYER_QUESTION,
        questions
    }
}

function setPlayIdUserId(playId, userId) {
    return {
        type: SET_PLAY_ID,
        playId,
        userId
    }
}
function setPlayerGender(gender) {
    return {
        type: SET_PLAYER_INFO_GENDER,
        gender,
    }
}

function setPlayerAge(age) {
    return {
        type: SET_PLAYER_INFO_AGE,
        age
    }
}

export function setStatus(status) {
    return {
        type: SET_STATUS,
        status
    }
}

export function setCurrentQuestion(currentQuestion) {
    return {
        type: SET_CURRENT_QUESTION,
        currentQuestion
    }
}

function setAnswer(answer) {
    return {
        type: SET_CURRENT_QUESTION_ANSWER,
        answer
    }
}


function setShareImg(shareImg){
    return {
        type: SET_SHARE_IMG,
        shareImg
    }
}


export function handleSetGender(gender) {
    return (dispatch, getState) => {
        const { player } = getState();
        const { playId } = player;
        API.answer(playId, 'gender', gender)
        dispatch(setPlayerGender(gender))

        if (gender == 'homem')
            questions[1].options = ['/assets/img/q1-man-a.jpg', '/assets/img/q1-man-b.jpg']
        else if (gender == 'mulher')
            questions[1].options = ['/assets/img/q1-girl-a.jpg', '/assets/img/q1-girl-b.jpg']
        else 
            questions[1].options = ['/assets/img/q1-girl-a.jpg', '/assets/img/q1-man-b.jpg']

        dispatch(setQuestion(questions))
        dispatch(setStatus(status.PLAYER_INFO_AGE))
    }
}

export function handleSetAge(age) {
    return (dispatch, getState) => {
        const { player } = getState();
        const { playId } = player;
        API.answer(playId, 'age', age)
        dispatch(setPlayerAge(age))
        dispatch(setStatus(status.PLAYER_STYLES))
    }
}

export function handleAnswerQuestion(value) {
    return (dispatch, getState) => {
        const { player } = getState();
        const { currentQuestion, questions, playId } = player;
        dispatch(setAnswer(value))
        API.answer(playId, 'q' + currentQuestion, value)
        console.log(player)
        if (currentQuestion == Object.keys(questions).length - 1) {
            handleSetRecomendations()(dispatch, getState)
            dispatch(setStatus(status.INSTRUCTION_FACE_READER))
        } else {
            dispatch(setCurrentQuestion(currentQuestion + 1))
        }
    }
}


export function uploadFile(blob, id) {
    return (dispatch, getState) => {
        const { player } = getState();
        const { playId } = player;

        API.uploadVideo(blob, playId, id)
    }
}

export function handleBackQuestion() {
    return (dispatch, getState) => {
        const { player } = getState();
        const { currentQuestion, questions } = player;

        if (currentQuestion == 0) {
            dispatch(setStatus(status.PLAYER_INFO_AGE))
        } else {
            dispatch(setCurrentQuestion(currentQuestion - 1))
        }
    }
}

export function handleEndFaceReader() {
    return (dispatch, getState) => {
        dispatch(setStatus(status.FACE_CALC))
    }
}

export function handleStartFaceGlass() {
    return (dispatch, getState) => {
        dispatch(setStatus(status.FACE_GLASS))
    }
}


export function handleShareImg(shareId){
    return async (dispatch) => {
        const json = await API.getShareImg(shareId);

        if(json.url)
            dispatch(setShareImg(json.url));
    }
}

export function handlePreLoadModules() {
    return async dispatch => {

        API.initGame(API.getSystemInfo).then(res => {
            dispatch(setPlayIdUserId(res.playId, res.userId))
        })
        tfjsWasm.setWasmPath(
            `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${
            version}/dist/tfjs-backend-wasm.wasm`);

        const model = await facemesh.load({ maxFaces: 1 });
        dispatch(setModel(model))
    }
}

function handleSetRecomendations(){
    return async (dispatch, getState) => {
        const { player } = getState();
        const { gender } = player;
        const { answer } = player.questions[0]
        let filter ;

        const options = [
            'Extroversão, energia, otimismo',
            'Sutileza, calmaria, sensibilidade',
            'Determinação, rapidez, intensidade',
            'Reflexão, constância, pragmatismo'
        ]
        if(gender == 'homem'){
            if(answer == options[0])
                filter = {"gender":["M"],"brand":[],"shape":["rectangle","square"],"material":[]}
            
            if(answer == options[1])
                filter = {"gender":["M"],"brand":[],"shape":["rectangle","round"],"material":[]}

            if(answer == options[2])
                filter = {"gender":["M"],"brand":[],"shape":["browline","round"],"material":[]}

            if(answer == options[3])
                filter = {"gender":["M"],"brand":[],"shape":["browline","aviator"],"material":[]}
        } else if(gender == 'mulher'){
            if(answer == options[0])
                filter = {"gender":["W"],"brand":[],"shape":["rectangle","square"],"material":[]}
            
            if(answer == options[1])
                filter = {"gender":["W"],"brand":[],"shape":["rectangle","round"],"material":[]}

            if(answer == options[2])
                filter = {"gender":["W"],"brand":[],"shape":["browline","cateye", "round"],"material":[]}

            if(answer == options[3])
                filter = {"gender":["W"],"brand":[],"shape":["browline","aviator"],"material":[]}
        } else {
            
            if(answer == options[0])
                filter = {"gender":[""],"brand":[],"shape":["rectangle","square"],"material":[]}
            
            if(answer == options[1])
                filter = {"gender":[""],"brand":[],"shape":["rectangle","round"],"material":[]}

            if(answer == options[2])
                filter = {"gender":[""],"brand":[],"shape":["browline","cateye", "round"],"material":[]}

            if(answer == options[3])
                filter = {"gender":[""],"brand":[],"shape":["browline","aviator"],"material":[]}
        }
        const results = await search(filter);
        const ids = []

        if(results <= 4){
           results.forEach((i, index) => ids.push(index))
        } else {
            while(ids.length != 4){
                const id = Math.floor(Math.random() * results.length)
                if(ids.includes(id)) continue;
                ids.push(id);
            }
        }
       


        dispatch(setRecomendations(ids.map(id => results[id])))
    }

}
