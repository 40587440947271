import React, { Component } from 'react'
import { connect } from 'react-redux'
import { handleSetAge, handleSetGender, setStatus } from '../redux/actions/player'
import { status } from '../utils/const';

export default function Instructions(props){

    return <div className="d-flex flex-fill flex-column align-items-center w-100" style={{ padding: '20px', paddingTop: '24px', paddingBottom: '24px' }}>
        <div className="d-flex text-center" style={{ fontSize: '24px', paddingTop: '24px', fontWeight: 'bold', lineHeight: 1.33 }}>{props.title}</div>
        <div className="d-flex text-center" style={{ fontSize: '18px', paddingTop: '32px', lineHeight: 1.5 }}>{props.subtitle}</div>
        <div className="d-flex w-100 flex-fill flex-column align-items-center justify-content-center img-instruction" style={{ background: `url('${props.img}') center center no-repeat`, backgroundSize: 'auto auto' }} >
            {/* <img src={props.img} style={{ fontSize: '32px', maxWidth: 'calc(100% - 16px)', maxHeight: 'calc(100% - 32px)', width: 'auto'}}  /> */}
        </div>
        <div className="d-flex text-center" onClick={() => props.btnClick()} style={{ cursor: "pointer", borderRadius: '8px', padding: '16px', fontSize: '18px', color: '#3a3838', background: '#ffc000', }}>{props.btn}</div>
    </div>
    
}
