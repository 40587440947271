import db from './db'

function getUserId() {
    return localStorage.userId || "0"
}

const apiUrl = "https://sunglass-api.herokuapp.com"
// const apiUrl = 'http://localhost:3002'

export function initGame() {
    return fetch(apiUrl + '/init-game/' + getUserId(), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            system: getSystemInfo(),
            url: window.location.href
        })
    })
        .then(res => res.json())
        .then(res => {
            localStorage.userId = res.userId
            return res;
        })
}

export function answer(playId, id, value) {
    return fetch(`${apiUrl}/answer/${playId}/${id}/${value}`)
        .then(res => res.json())
}


export function getShareImg(shareId) {
    return fetch(`${apiUrl}/share/${shareId}`)
        .then(res => res.json())
}

export function uploadVideo(blob, playId, id) {
    const data = new FormData();
    data.append('file', blob);
    return fetch(`${apiUrl}/upload/` + playId + '/' + id, {
        method: 'POST',
        body: data,
        enctype: 'multipart/form-data',
    })
        .then(res => res.json())
}


export function share(sku, blob, playId) {
    const data = new FormData();
    data.append('file', blob);
    return fetch(`${apiUrl}/share/` + playId + '/' + sku, {
        method: 'POST',
        body: data,
        enctype: 'multipart/form-data',
    })
        .then(res => res.json())
}

export function getSystemInfo() {
    var module = {
        options: [],
        header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
        dataos: [
            { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
            { name: 'Windows', value: 'Win', version: 'NT' },
            { name: 'iPhone', value: 'iPhone', version: 'OS' },
            { name: 'iPad', value: 'iPad', version: 'OS' },
            { name: 'Kindle', value: 'Silk', version: 'Silk' },
            { name: 'Android', value: 'Android', version: 'Android' },
            { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
            { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
            { name: 'Macintosh', value: 'Mac', version: 'OS X' },
            { name: 'Linux', value: 'Linux', version: 'rv' },
            { name: 'Palm', value: 'Palm', version: 'PalmOS' }
        ],
        databrowser: [
            { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
            { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
            { name: 'Safari', value: 'Safari', version: 'Version' },
            { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
            { name: 'Opera', value: 'Opera', version: 'Opera' },
            { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
            { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
        ],
        init: function () {
            var agent = this.header.join(' '),
                os = this.matchItem(agent, this.dataos),
                browser = this.matchItem(agent, this.databrowser);

            return { os: os, browser: browser };
        },
        matchItem: function (string, data) {
            var i = 0,
                j = 0,
                html = '',
                regex,
                regexv,
                match,
                matches,
                version;

            for (i = 0; i < data.length; i += 1) {
                regex = new RegExp(data[i].value, 'i');
                match = regex.test(string);
                if (match) {
                    regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                    matches = string.match(regexv);
                    version = '';
                    if (matches) { if (matches[1]) { matches = matches[1]; } }
                    if (matches) {
                        matches = matches.split(/[._]+/);
                        for (j = 0; j < matches.length; j += 1) {
                            if (j === 0) {
                                version += matches[j] + '.';
                            } else {
                                version += matches[j];
                            }
                        }
                    } else {
                        version = '0';
                    }
                    return {
                        name: data[i].name,
                        version: parseFloat(version)
                    };
                }
            }
            return { name: 'unknown', version: 0 };
        }
    };

    
    var e = module.init()

    return [
        e.os.name,
        e.os.version,
        e.browser.name,
        e.browser.version
    ]

}

function hasProps(arr, propArr){
    for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if(propArr.includes(item)) return true;
    }
    return false;
}

export async function search(params, name){
    // alert(db)
    const {gender, brand, shape, material} = params;
    console.log(JSON.stringify(params))
    const result = db.filter(i => 
            (brand.length == 0 || brand.includes(i.meta.brand)) &&
            (shape.length == 0 || hasProps(shape, i.meta.shape)) &&
            (material.length == 0 ||  hasProps(material, i.meta.material)) &&
            (gender.length == 0 || gender.includes(i.meta.gender) || i.meta.gender == "undefined") &&
            (!name || i.name.toLowerCase().includes(name.toLowerCase()) )
        )

    
    return result //.slice(0,20)
}

