import { TOGGLE_FILTER, SET_SHARE_ID, CLEAR_FILTER, SET_RESULTS } from '../actions/filter'
import React from 'react'


const brands = {
    "5953bea50ec85313791c9aa7": "Ray-Ban",
    "": "Carrera",
    "": "Oakley",
    "": "AliExpress",
    "59a69562645e5a6d9aa94727": "Persol",
    "59b2b45a553d246a2c652638": "Polaroid",
    "5991c693e40a5f493aa48c4a": "-",
}

const filters = {
    'gender': {
        title: 'Gênero dos modelos',
        type: 'options-big',
        options: [
            { value: 'M', name: 'Masculino' },
            { value: 'W', name: 'Feminino' }
        ],
        selected: []
    },

    'brand': {
        title: 'Marca',
        type: 'options',
        options: [
            { value: "5953bea50ec85313791c9aa7", name: "Ray-Ban" },
            { value: "5975fa7213beab44d3b6c6cc", name: "Carrera" },
            { value: "5979e77f3d20d717d895fefd", name: "Oakley" },
            { value: "598c5cc3fc64dd436fac149b", name: "AliExpress" },
            { value: "59a69562645e5a6d9aa94727", name: "Persol" },
            { value: "59b2b45a553d246a2c652638", name: "Polaroid" },
            // { value: "5991c693e40a5f493aa48c4a", name: "Outras" }, 
        ],
        selected: []
    },

    'shape': {

        title: 'Armação',
        type: 'options-img',
        options: [
            { value: "rectangle", name: 'Retangular' },
            { value: "square", name: 'Quadrado' },
            { value: "round", name: 'Redondo' },
            { value: "aviator", name: 'Aviador' },
            { value: "cateye", name: 'Gatinho' },
            { value: "browline", name: 'Browline' },
            { value: "sportly", name: 'Esportivo' },
            { value: "single", name: 'Máscara' }
        ],
        render: (item) => <div className="d-flex flex-column">
            <img src={`/assets/img/${item.value}.svg`} />
            {item.name}
        </div>,
        selected: []
    },

    'material': {
        title: 'Meterial',
        type: 'options',
        options: [
            { value: 'aluminium', name: 'Alumínio' },
            { value: 'leather', name: 'Couro' },
            { value: 'wood', name: 'Madeira' },
            { value: 'metal', name: 'Metal' },
            { value: 'plastic', name: 'Plástico' },
            { value: 'fabric', name: 'Tecido' },
            { value: 'titanium', name: 'Titânio' }
        ],
        selected: []
    }
}

export default function filter(state = { filters }, action) {
    switch (action.type) {
        case TOGGLE_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.filterType]: {
                        ...state.filters[action.filterType],
                        selected: state.filters[action.filterType].selected.includes(action.value) ? state.filters[action.filterType].selected.filter(v => v !== action.value) : state.filters[action.filterType].selected.concat(action.value)
                    }
                }

            }
        case CLEAR_FILTER:
            return { filters, results: [] };
        case SET_SHARE_ID:
            return {
                ...state,
                shareId: action.shareId
            }
        case SET_RESULTS:
            return {
                ...state,
                results: action.results
            };
        default:
            return state
    }
}