import { search, share } from '../../utils/api'
export const TOGGLE_FILTER = 'TOGGLE_FILTER'
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const SET_RESULTS = 'SET_RESULTS'

export const SET_SHARE_ID = 'SET_SHARE_ID'
function setShareId(shareId){
    return {
        type: SET_SHARE_ID,
        shareId
    }
}

export function handleShare(sku, blob){
    return async (dispatch, getState) => {
        const { player } = getState();
        const { playId } = player;
        const result = await share(sku, blob, playId);

        dispatch(setShareId(result.id))
    }
}

function toggleFilter(filterType, value) {
    return {
        type: TOGGLE_FILTER,
        filterType,
        value
    }
}
function clearFilter() {
    return {
        type: CLEAR_FILTER
    }
}
function setResults(results) {
    return {
        type: SET_RESULTS,
        results
    }
}

export function handleClearFilter() {
    return async (dispatch, getState) => {
        dispatch(clearFilter())
        const { filter } = getState()
        // console.log('filtering', search())
        dispatch(setResults(await search(getFilter(filter))))

    }
}

export function handleToggleFilter(filterType, value) {
    return async (dispatch, getState) => {
        dispatch(toggleFilter(filterType, value))
        const { filter } = getState()
        // console.log('filtering', search())

        dispatch(setResults(await search(getFilter(filter))))
    }
}

export function handleSearchByName(name) {
    return async (dispatch, getState) => {
        const { filter } = getState()
        // console.log('filtering', search())



        dispatch(setResults(await search(getFilter(filter), name)))
    }
}

function getFilter(filter) {
    const gender = filter.filters.gender.selected;
    const brand = filter.filters.brand.selected;
    const shape = filter.filters.shape.selected;
    const material = filter.filters.material.selected;

    return {
        gender,
        brand,
        shape,
        material
    }
}