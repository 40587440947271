import React, { Component } from 'react'
import { connect } from 'react-redux'
import { handleSetAge, handleSetGender, setStatus } from '../redux/actions/player'
import { status } from '../utils/const';
import Instructions from './Instructions';

class Start extends Component{

    //
    //
    ///
    //
    render(){
        return <Instructions 
                title={'Quer descobrir os melhores modelos de óculos de sol para você?'}
                subtitle={'Faremos rápidas perguntas e uma análise do seu rosto.'}
                img={'/assets/img/start.svg'}
                btnClick={() => this.props.dispatch(setStatus(status.PLAYER_INFO_GENDER))}
                btn={'Sim, quero!'}
        />
    }
}


export default connect()(Start)