import React, { Component } from 'react'
import { uploadFile,  } from '../redux/actions/player';
import { handleShare,  } from '../redux/actions/filter';
import { connect } from 'react-redux';
import Filter from './Filter';





class FaceGlass extends Component {



    state = {
        selected: '',
        selectedByCustom: false,
        popup: false,
        filter: false,
        img: '',
        items: []
    }

    componentDidMount() {
        const selected = this.props.recomendations[0].sku
        this.setState({
            items: this.props.recomendations,
            selected
        })

        setTimeout(() => {
            const ratio = 763 / this.canvasContainer.offsetHeight
            // this.canvas.width = 
            window.JEEWIDGET.start({
                sku: selected,
                searchImageMask:  window.location.href + 'assets/img/logo.png',
                searchImageColor: 0xff0000,
                onError: function (errorLabel) { alert('Ocorreu um erro. Tente novamente mais tarde. Erro: ' + errorLabel); }
            })

            // window.JeefitFallback.switch_toFallbackMode()
            this.started = true;
            // this.setUpRecord(this.canvas.captureStream())
        }, 100)

        // this.canvas.width = 900 
        // this.canvas.height = ( this.canvasContainer.offsetHeight * 900) /  this.canvasContainer.offsetWidth
    }

    changeModel(src) {
        window.JEEWIDGET.load(src)
        this.setState({ selected: src })
    }

    onSelect(e) {
        if (!e) {
            this.setState({ filter: false, selectedByCustom: false })
            this.changeModel(this.state.items[0].sku)
        } else {
            this.setState({ filter: false, selectedByCustom: true })
            this.changeModel(e)
        }
    }

    openFilter() {

        this.setState({ filter: true, selectedByCustom: false })
        // if(this.started)
        // this.changeModel(items[0].src)
    }


    async setUpRecord(video) {
        if (!window.MediaRecorder) return;

        let options = { mimeType: 'video/mp4;' };
        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
            options = { mimeType: 'video/webm;codecs=vp9' };
        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
            options = { mimeType: 'video/webm;codecs=h264' };
        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
            options = { mimeType: 'video/webm;codecs=vp8' };
        }
        let mediaRecorder = new MediaRecorder(video, options);

        let chunks = [];
        this.mediaRecorder = mediaRecorder
        mediaRecorder.ondataavailable = function (ev) {
            chunks.push(ev.data);
        }
        mediaRecorder.onstop = (ev) => {
            let blob = new Blob(chunks, { 'type': 'video/mp4;' });
            chunks = [];
            if (blob.size !== 0)
                this.props.dispatch(uploadFile(blob, 'glass'))
        }

        setInterval(() => {
            this.restart()
        }, 10 * 1000)
        this.mediaRecorder.start();

    }

    restart() {
        this.mediaRecorder.stop();
        this.mediaRecorder.start();

    }


    componentDidUpdate(prevProps) {
        // if(this.props.src !== prevProps.src && this.started){
        //     window.JEEWIDGET.load( this.props.src)
        // }
    }

    share() {
        const dataURL = this.canvas.toDataURL();
        var blobBin = atob(dataURL.split(',')[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        var file = new Blob([new Uint8Array(array)], { type: 'image/png' });
        this.props.dispatch(handleShare(this.state.selected, file))
        this.setState({
            popup: true,
            img: dataURL
        })
    }

    render() {
        const { selected, tab, items } = this.state
        const shareUrl =  !this.props.shareId ?  "" : window.location.href + "share/" + this.props.shareId
        const text = (`Olha como ficou bem esse óculos em mim!!
Experimente você também:

${shareUrl}`);
        const shareHref = encodeURIComponent ? encodeURIComponent(text) : text;
        // alert(this.state.popup)
        // alert(window.innerHeight + ', ' + window.outerHeight)
        return <React.Fragment>
            <div className="d-flex flex-fill flex-column position-relative align-items-center" style={{ maxHeight: (window.innerHeight) + 'px', overflow: 'hidden', }}>

                <div className="d-flex flex-fill w-100  justify-content-center align-items-center" ref={ref => this.canvasContainer = ref} id='JeeWidget' style={{ maxWidth: '940px', maxWidth: '747px', height: `calc(100% - 104px)` }}>
                    <canvas className="w-100 h-100" id='jeefitCanvas' ref={ref => this.canvas = ref} ></canvas>
                </div>

                <div className="position-absolute d-flex justify-content-center" onClick={() => this.share()} style={{
                    bottom: this.state.selectedByCustom ? '16px' : '120px',
                    right: '16px'
                }}>
                    <a 
                    className="d-flex justify-content-center align-items-center"
                    style={{
                        height: '48px',
                        width: '48px',
                        borderRadius: '100px',
                        background: '#ffc000',
                    }}
                        target="_blank"
                    >
                        <img style={{ height: '24px' }} src="/assets/img/share.svg" />
                    </a>
                </div>

                {

                    !this.state.selectedByCustom ? <a
                        className="position-absolute"
                        style={{
                            top: '16px',
                            left: '16px',
                            zIndex: 10,
                            padding: '8px 12px',
                            borderRadius: '100px',
                            background: '#f9f9f9',
                            textAlign: 'center',
                            fontSize: '16px',
                            color: '#363636'
                        }}
                        onClick={() => this.openFilter()}
                    > Ver outros  </a> : <a
                        className="position-absolute d-flex align-items-center"
                        style={{
                            top: '16px',
                            left: '16px',
                            zIndex: 10,
                            padding: '8px 12px',
                            borderRadius: '100px',
                            background: '#f9f9f9',
                            textAlign: 'center',
                            fontSize: '16px',
                            color: '#363636'
                        }}
                        onClick={() => this.openFilter()}
                    >  <img className="d-flex" style={{ height: '16px', marginRight: '8px' }} src={'/assets/img/back.svg'} /> Voltar  </a>

                }
                {
                    !this.state.selectedByCustom && (
                        <div className="w-100 d-flex position-absolute" style={{
                            height: 104,
                            background: 'white',
                            padding: '16px',
                            overflow: 'auto',
                            bottom: 0
                        }}>

                            {items.map((i, index) =>
                                selected == i.sku ?
                                    <div key={i.sku} className="d-flex align-items-center " style={{ borderRadius: '15px', padding: '2px', border: '1px solid #3a3838', paddingLeft: '8px', paddingRight: '8px', marginLeft: index == 0 ? '0px' : '16px' }}>

                                        {(index == 0) && <img style={{ width: '95px', marginRight: '8px' }} src={i.meta.productImageURL} className="d-flex h-100" />}
                                        <div className="flex-column" style={{ color: '#3a3838', padding: '8px' }}>
                                            {i.name}
                                        </div>
                                        {(index !== 0) && <img src={i.meta.productImageURL} style={{ width: '95px' }} className="d-flex h-100" />}
                                    </div> :
                                    <div onClick={() => this.changeModel(i.sku)} key={i.sku} className="d-flex align-items-center justify-content-center" style={{ marginLeft: index == 0 ? '0px' : '16px' }}>
                                        <img src={i.meta.productImageURL} className="d-flex h-100" style={{ width: '95px' }} />
                                    </div>
                            )}


                        </div>

                    )}

            </div>


            {
                this.state.filter && <div className="w-100 h-100 position-absolute" style={{ zIndex: 1000, top: 0, left: 0 }} ><Filter onSelect={e => this.onSelect(e)} /></div>
            }

            {
                this.state.popup && <div className="w-100 h-100 d-flex align-items-center blur-background" style={{
                    position: 'absolute',
                    zIndex: 1000,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    top: '0px',
                    left: '0px'
                }}>
                    <div className="d-flex flex-column w-100" style={{
                        background: 'white',
                        borderRadius: '4px',
                        color: '#3a3838',
                        padding: '16px'
                    }}>
                        <div className="w-100 text-right" style={{ fontSize: '18px', marginBottom: '24px' }}> <img src="/assets/img/cross.svg" onClick={() => this.setState({ popup: false })} /> </div>
                        <img src={this.state.img} style={{
                            objectFit: 'contain',
                            height: (window.innerHeight * .55) + 'px',
                            marginBottom: '16px'
                        }} />
                        <div className="w-100 " style={{ fontSize: '14px', marginBottom: '32px' }}>
                            Olha como ficou bem esse óculos em mim!!<br />
                            Experimenta você também:<br />
                            <a href={shareUrl}>{shareUrl}</a>
                        </div>

                        <a style={{
                            height: '56px',
                            width: '100%',
                            borderRadius: '8px',
                            background: '#ffc000',
                            lineHeight: '54px',
                            textAlign: 'center',
                            fontSize: '18px',
                            color: '#363636',
                            opacity: this.props.shareId ? 1 : .5
                        }}
                            href={this.props.shareId ? ("whatsapp://send?text=" + shareHref) : "#"}
                        >
                            Compartilhar
                        </a>

                    </div>
                </div>
            }
        </React.Fragment>
    }
}

function mapStateToProps({ player, filter }) {
    return {
        recomendations: player.recomendations,
        shareId: filter.shareId
    }
}

export default connect(mapStateToProps)(FaceGlass);

