import React from 'react'

export default function Progress({ percent , onCenter, borderRadius, color }) {
    return <div className={"d-flex w-100 position-relative " + (onCenter ? 'justify-content-center' : '')}
            style={{
                borderRadius: borderRadius ? '100px' : 0,
                height: 8
            }}
    >
    <div className="d-flex progress-bar" style={{
        width: onCenter ? (100 - percent) + '%' : percent + '%',
        zIndex: 1,
        borderRadius: onCenter || borderRadius ? '10px' : '0 10px 10px 0',
        background: color ? color : '#363636'
    }}>

    </div>
        <div className="d-flex w-100 " style={{
            background: '#e4e4e5',
            height: '100%',
            position: 'absolute',
            borderRadius: borderRadius ? '100px' : 0
        }}>
        </div>
    </div>
}