import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setPlayerInfo, handleAnswerQuestion, handleBackQuestion } from '../redux/actions/player'
import Page from './Page'

class User extends Component {


    answer(value) {
        this.props.dispatch(handleAnswerQuestion(value))
    }


    renderOptions(currentQuestion){
        
        const { type } = currentQuestion;

        if(type == 'mult-text')
            return <div className="d-flex flex-fill flex-column" style={{ height: '100%', paddingBottom: '32px'}} >
            {currentQuestion.options.map((a, index) => (
                <div key={a} onClick={() => this.props.dispatch(handleAnswerQuestion(a))} className="d-flex w-100 gender-box " style={{ fontSize: '16px', padding: '12px' , fontWeight: currentQuestion.answer == a ? 'bold': 'normal', background: currentQuestion.answer == a ? '#ffc000' : '#ffffff',  marginTop: index == 0 ? '0px': '8px' }}>
                    {a}
                </div>
            ))}
        </div>
        const hasAnswer = currentQuestion.answer && currentQuestion.answer !== ''

        return    <div className="d-flex flex-fill justify-content-center h-100 align-items-center" style={{  paddingBottom: '32px', }} >
        {currentQuestion.options.map((a, index) => (
            <div key={a} onClick={() => this.props.dispatch(handleAnswerQuestion(currentQuestion.values[index]))} className="d-flex h-100 flex-fill flex-column align-items-center justify-content-center img-instruction" style={{ marginRight: index == 0 ? '24px': '0px', background: `url('${a}') center center no-repeat`, backgroundSize: 'auto auto' , border: hasAnswer && currentQuestion.answer == currentQuestion.values[index] ? `solid 1.5px #363636` : 'none', opacity: hasAnswer && currentQuestion.answer !== currentQuestion.values[index] ? .76 : 1, borderRadius: '10px' }} >
            </div>
        ))}
        
    </div>
    }

    render() {

        const { currentQuestion } = this.props

        if (!currentQuestion) return;
            
        
        return <Page title={currentQuestion.title} subtitle={currentQuestion.subtitle} step={3 + currentQuestion.step} back={() => this.props.dispatch(handleBackQuestion())}>
                {this.renderOptions(currentQuestion)}
        </Page>


 
        // <div className="d-flex flex-fill flex-column w-100">
        //     <p>{currentQuestion.id}) {currentQuestion.question}</p>
        //     <br />
        //     {currentQuestion.options.map(input => <button key={input} onClick={() => this.answer(input)}  style={{ fontWeight: currentQuestion.answer == input ? 'bold': 'normal' }}>{input}</button>)}
        //     <br />
        //     <button onClick={() => this.props.dispatch(handleBackQuestion())}>Voltar</button>
        // </div>
    }
}

function mapStateToProps({ player }) {
    return {
        currentQuestion: player.questions[player.currentQuestion],
    }
}

export default connect(mapStateToProps)(User)